import React from 'react'
import Modal from 'react-modal'

// Physicians
import aklFahed from '../images/team/physicians/akl-fahed.png'
import christelleYounes from '../images/team/physicians/christelle-younes.png'
import clairRobbins from '../images/team/physicians/clair-robbins.png'
import danielHall from '../images/team/physicians/daniel-hall.png'
import davidBarlow from '../images/team/physicians/david-barlow.png'
import franciscoJBarrera from '../images/team/physicians/francisco-j-barrera.png'
import haydenBox from '../images/team/physicians/hayden-box.png'
import ioannisKoutsourelakis from '../images/team/physicians/ioannis-koutsourelakis.png'
import jasonMalley from '../images/team/physicians/jason-malley.png'
import karimSleiman from '../images/team/physicians/karim-sleiman.png'
import mariaDouridaMitarachi from '../images/team/physicians/maria-dourida-mitarachi.png'
import roukozAbouKaram from '../images/team/physicians/roukoz-abou-karam.png'
import samanthaWu from '../images/team/physicians/samantha-wu.png'
import stephanieGianoukos from '../images/team/physicians/stephanie-gianoukos.png'
import tarekSouaid from '../images/team/physicians/tarek-souaid.png'
import yousefBadran from '../images/team/physicians/yousef-badran.png'
// Experts
import abigailFreyer from '../images/team/experts/abigail-freyer.png'
import adrianaKonstantakopoulos from '../images/team/experts/adriana-konstantakopoulos.png'
import briannaWells from '../images/team/experts/brianna-wells.png'
import courtneyReynolds from '../images/team/experts/courtney-reynolds.png'
import jenniferGraham from '../images/team/experts/jennifer-graham.png'
import joannaGholmie from '../images/team/experts/joanna-gholmie.png'
import laurenChaunt from '../images/team/experts/lauren-chaunt.png'
import lindaSaid from '../images/team/experts/linda-said.png'
import michaelSoares from '../images/team/experts/michael-soares.png'
import mileydisZambrana from '../images/team/experts/mileydis-zambrana.png'
import natalijaTrojanovic from '../images/team/experts/natalija-trojanovic.png'
import nikiSaccareccia from '../images/team/experts/niki-saccareccia.png'
import rachelBordo from '../images/team/experts/rachel-bordo.png'
import razmigAzezian from '../images/team/experts/razmig-azezian.png'
import tjSnediker from '../images/team/experts/tj-snediker.png'
import torySheehan from '../images/team/experts/tory-sheehan.png'
import julietaAlfonso from '../images/team/experts/julieta-alfonso.png'
import guyleneVelez from '../images/team/experts/guylene-velez.png'
// Geometry
import RingFilled from '../../assets/geometry/ring-filled.svg'
import Ring from '../../assets/geometry/ring.svg'
import Line from '../../assets/geometry/line.svg'
import Circle from '../../assets/geometry/circle.svg'
import { Link } from 'gatsby'

const physicians = [
  {
    title: 'Leadership',
    anchorLink: 'leadership',
    people: [
      {
        name: 'Akl Fahed, MD, MPH',
        role: 'Chief Medical and Scientific Advisor',
        imageUrl: aklFahed,
        altText: 'Akl Fahed, MD, MPH.',
        bio:
          'Dr. Fahed is a physician-scientist, healthcare innovation leader, and Goodpath co-founder. He is an interventional cardiologist at Massachusetts General Hospital and faculty member at Harvard Medical School. He is triple-board certified in internal medicine, cardiology, and interventional cardiology. He obtained his MD degree from the American University of Beirut where he was inducted to the Alpha Omega Alpha Medical Honor Society, and his MPH from the Harvard T. H. Chan School of Public Health. He completed his residency in internal medicine, fellowship in cardiology, and fellowship in interventional cardiology all at Massachusetts General Hospital and Harvard Medical School. He also completed five years of postdoctoral research fellowships in genomics and data science at Harvard Medical School and the Broad Institute of MIT and Harvard, where he continues to be an NIH-funded investigator. Dr. Fahed works nationally to advance innovation in medicine and has served in people roles at the American College of Cardiology. He co-founded Goodpath because he believes we can improve quality of life for millions of people suffering from commmon but disabling conditions in a value-based care model.',
        modal: 'fahedModal'
      },
      {
        name: 'Samantha Wu, MD, MSPH',
        role: 'Medical Director, Clinical Development',
        imageUrl: samanthaWu,
        altText: 'Samantha Wu, MD, MSPH.',
        bio:
          'Dr. Wu is an Internal Medicine doctor with a background in Public Health. Her area of clinical practice involves caring for adults with acute and acute-on-chronic medical conditions. She received a MSPH from Johns Hopkins Bloomberg School of Public Health and subsequently obtained her MD from Harvard Medical School. She completed Internal Medicine residency at Massachusetts General Hospital. She is interested in the use of technology to increase access and delivery of evidence-based, integrative care for those with chronic conditions.',
        modal: 'wuModal'
      }
      // More people...
    ]
  },
  {
    title: 'Musculoskeletal Conditions Leadership',
    anchorLink: 'mskLeadership',
    people: [
      {
        name: 'Hayden Box, MD',
        role: 'Clinical Lead, Musculoskeletal',
        imageUrl: haydenBox,
        altText: 'Hayden Box, MD.',
        bio:
          'Dr. Box is a board-certified orthopaedic surgeon practicing in Salem, NH. He is a hip and knee specialist with a focus in primary and complex revision hip and knee replacement. He graduated summa cum laude from Vanderbilt University in Nashville, TN with an undergraduate degree in biomedical engineering. He then earned his MD degree from Harvard Medical School. He did his orthopaedic surgeon residency training at the University of Texas Southwestern Medical Center in Dallas, TX and completed an advanced hip and knee replacement fellowship at Massachusetts General Hospital in Boston, MA. He believes in a collaborative multidisciplinary approach to healthcare and is proud member of the Goodpath musculoskeletal team.',
        modal: 'boxModal'
      },
      {
        name: 'Stephanie Gianoukos, MD, MPH',
        role: 'Clinical Lead, Pain Management',
        imageUrl: stephanieGianoukos,
        altText: 'Stephanie Gianoukos, MD, MPH.',
        bio:
          "Dr. Stephanie Gianoukos is part of the medical team at Goodpath.  She is an expert in Pain Management and specializes in multi-modal approach to treating chronic pain.  She earned her medical degree from UMASS Medical School.  She obtained her Anesthesiology residency training at St Elizabeth's Medical Center in Boston, MA and her Pain Fellowship in the same institution.  She has been working with acute and chronic pain patients for more than 10 years spearheading two Pain Clinics in the greater Boston area.  She believes that there is no magic bullet to treat chronic pain and that the most effective approach to managing this type of pain is utilizing a multi-modal treatment model.  Patients with chronic pain progress faster when they combine and utilize tools from different disciplines.  It is this amalgamation of treatment modalities that leads to better pain management and improved quality of life.",
        modal: 'gianoukosModal'
      }
      // More people...
    ]
  },
  {
    title: 'Sleep Disorders Leadership',
    anchorLink: 'sleepLeadership',
    people: [
      {
        name: 'Ioannis Koutsourelakis, MD, MSc, PhD',
        role: 'Clinical Lead, Sleep',
        imageUrl: ioannisKoutsourelakis,
        altText: 'Ioannis Koutsourelakis, MD, MSc, PhD.',
        bio:
          'Dr. Ioannis Koutsourelakis is an Ear, Nose and Throat (ENT) surgeon specialized in sleep medicine. After finishing Otolaryngology residency in Greece, he completed fellowships at Sint Lucas Andreas Hospital, Amsterdam; Johns Hopkins Medicine, Baltimore; and Weill Cornell Medicine, New York. Dr Koutsourelakis has dedicated the last 15 years to developing a deeper understanding of the pathophysiology of sleep disorders as well as mastering state-of-the art treatments for patient care. His research, including randomized trials on device treatments for sleep disorders, has been widely cited in leading international medical journals.',
        modal: 'koutsourelakisModal'
      }
      // More people...
    ]
  },
  {
    title: 'Digestive Health Leadership',
    anchorLink: 'digestiveLeadership',
    people: [
      {
        name: 'Yousef Badran, MD',
        role: 'Clinical Lead, Digestive Health',
        imageUrl: yousefBadran,
        altText: 'Yousef Badran, MD',
        bio:
          "Dr. Badran is a physician-scientist and Gastroenterology fellow at the Massachusetts General Hospital. He recieved his MD from the University of Jordan and then did a postdoctoral fellowship in Immunology at Boston Children's Hospital. He completed residency in Internal Medicine and served as a Chief Medical Resident at the Massachusetts General Hospital. He is a board certified internal medicine physician who practices at the Massachusetts General Hospital and Newton Wellsely Hospital. He is currently pursuing a post-doctoral research fellowship in genomics and immunology. Dr. Badran is passionate about using technology to improve patient-centered healthcare delivery.",
        modal: 'badranModal'
      }
      // More people...
    ]
  },
  {
    title: 'Long COVID Leadership',
    anchorLink: 'covidLeadership',
    people: [
      {
        name: 'Jason Malley, MD',
        role: 'Lead Advisor for Long COVID',
        imageUrl: jasonMalley,
        altText: 'Jason Malley, MD.',
        bio:
          'Dr. Jason Maley is a physician, researcher, and educator at Harvard Medical School and Beth Israel Deaconess Medical Center (BIDMC). As a pulmonary and critical care medicine specialist, he directs the BIDMC Critical Illness and COVID-19 Survivorship Program, a clinical and research program dedicated to improving the lives of people with Long COVID. He is co-chair of the American Academy of Physical Medicine and Rehabilitation PASC (Long COVID) Clinic Collaborative. Dr. Maley completed his BS in biomedical engineering from Boston University, MD from Tulane Medical School, and MS in epidemiology from the Harvard T.H. Chan School of Public Health. He was an internal medicine resident and chief resident at the University of Pennsylvania, and completed his pulmonary and critical care fellowship in the Harvard Combined Program at Massachusetts General Hospital and Beth Israel Deaconess Medical Center. He is board-certified in Internal Medicine, Pulmonary Medicine, and Critical Care Medicine.',
        modal: 'malleyModal'
      }
      // More people...
    ]
  },
  {
    title: 'Mental Health Leadership',
    anchorLink: 'mentalLeadership',
    people: [
      {
        name: 'David Barlow, PhD',
        role: 'Senior Scientific Advisor, Mental Health',
        imageUrl: davidBarlow,
        altText: 'David Barlow, PhD.',
        bio:
          'Dr. David H. Barlow is Professor of Psychology and Psychiatry, Emeritus and the Founder of the Center for Anxiety and Related Disorders at Boston University. He has published over 650 articles and chapters and over 90 books and clinical manuals and developed the Unified Protocol for Transdiagnostic Treatment of Emotional Disorders. He is the recipient of numerous awards, including honorary degrees from the University of Vermont and William James College, and the two highest awards in psychology, the Distinguished Scientific Award for Applications of Psychology from the American Psychological Association and James McKeen Cattell Fellow Award from the Association for Psychological Science honoring individuals for their lifetime of significant intellectual and clinical achievements.',
        modal: 'barlowModal'
      },
      {
        name: 'Clair Robbins, PhD',
        role: 'Scientific Advisor, Mental Health',
        imageUrl: clairRobbins,
        altText: 'Clair Robbins, PhD.',
        bio:
          'Dr. Robbins is a licensed Clinical Psychologist. She is an expert in the application of evidence-based psychological treatments to help individuals struggling to manage strong emotions. She is also a co-author of the Unified Protocol for Transdiagnostic Treatment of Emotional Disorders. She specializes in providing the Unified Protocol to adults and adolescents struggling to manage strong emotions; she also has expertise in treating anger and misophonia. She earned a Ph.D. in Clinical Psychology from Boston University. She completed a pre-doctoral internship at the Durham VA and postdoctoral training at Duke University Medical Center. Dr. Robbins is currently a staff psychologist at Triangle Area Psychology (TAP) Clinic in Durham, NC where she provides clinical services to patients and trains therapists around the country in delivery of the Unified Protocol. She is passionate about providing evidence-based therapies treatment that help people manage their emotions in ways that are consistent with their long-term goals as well as training other providers to deliver these treatments.',
        modal: 'robbinsModal'
      }
      // More people...
    ]
  },
  {
    title: 'Mind-body Medicine',
    anchorLink: 'mindMedicine',
    people: [
      {
        name: 'Maria Dourida-Mitarachi, PhD, MSc, PGCert',
        role: 'Clinical Lead, Mind-Body Medicine',
        imageUrl: mariaDouridaMitarachi,
        altText: 'Maria Dourida-Mitarachi, PhD, MSc, PGCert.',
        bio:
          'Dr Dourida-Mitarachi is a physical therapist with clinical experience in the UK, Belgium and Greece. She holds postgraduate degrees in Physiotherapy (MSc) and Education (PGCert) from the University of East London, UK. She completed her PhD at the Medical School of the National and Kapodistrian University of Athens, Greece. She currently teaches at the National and Kapodistrian University of Athens, the University of Nicosia, Cyprus and the University of  East London, UK. For the past 10 years she has researched the human stress system and exercise. She is a member of the American College of Sports Medicine (ACSM) and the Chartered Society of Physiotherapy (CSP), a Committee member of Exercise is Medicine-Hellas and an Advisory board member of Exercise is Medicine-Cyprus. She is interested in the mind-body techniques and how they affect the physiological responses.',
        modal: 'mitarachiModal'
      },
      {
        name: 'Daniel Hall, PhD',
        role: 'Scientific Advisor, Integrative Health',
        imageUrl: danielHall,
        altText: 'Daniel Hall, PhD.',
        bio:
          'Dr. Hall is a staff psychologist and an Assistant Professor of Psychology in the Department of Psychiatry. He is affiliated with the following centers and programs (listed alphabetically): MGH Behavioral Medicine, MGH Benson-Henry Institute for Mind-Body Medicine, MGH Cancer Center, Cancer Outcomes Research and Education (CORE) and Survivorship Program, MGH Mongan Institute, Health Policy Research Center. Dr. Hall treats adults who are coping with chronic or life-threatening illness, such as cancer. He also treats adults who are seeking to change health behaviors, such as sleep/insomnia. Dr. Hall primarily teaches patients skills using cognitive behavioral therapy (CBT), mindfulness meditation, and training in the relaxation response. He also provides clinical supervision to psychology trainees. Dr. Hall conducts research on how patients experience and manage uncertainty, stress, and health behaviors.',
        modal: 'hallModal'
      }
      // More people...
    ]
  },
  {
    title: 'Research and Clinical Development',
    anchorLink: 'researchDevelopment',
    people: [
      {
        name: 'Christelle Younes, MD',
        role: 'Associate Medical Director',
        imageUrl: christelleYounes,
        altText: 'Christelle Younes, MD.',
        bio:
          "I'm a primary care physician based in Montreal, Canada. I earned my medical doctor degree from l'Université Saint-Joseph de Beyrouth followed by residency training in family medicine at Hȏtel-Dieu de France. My experience in primary healthcare is focused on providing continuous care, both therapeutic and preventive, for people of all ages and through all stages of life. My approach to wellness is patient-centered, targets physical comfort as well as emotional and social support, while adapting to the emerging health challenges in the patient's community.",
        modal: 'younesModal'
      },
      {
        name: 'Roukoz Abou-Karam, MD',
        role: 'Associate Medical Director',
        imageUrl: roukozAbouKaram,
        altText: 'Roukoz Abou-Karam, MD.',
        bio:
          "I'm a physician with expertise in medical review and research. I currently serve as a postdoctoral research fellow at Massachusetts General Hospital, Harvard Medical School. My research is focused on predicting and improving clinical outcomes for patients with valvular heart disease. I earned my Bachelor's degree from the American University of Beirut and Medical degree from the St. George's Hospital University Medical Center.",
        modal: 'karamModal'
      },
      {
        name: 'Karim Sleiman, MD',
        role: 'Physician Researcher',
        imageUrl: karimSleiman,
        altText: 'Karim Sleiman, MD.',
        bio:
          'I am a primary care physician based between Tennessee (US) and Beirut (Lebanon). My medical education focused on research design for non-communicable diseases. I completed my residency training in internal medicine at the University of Tennessee Medical Center. My postdoctoral training was focused on imaging of age-related diseases at Duke University. My clinical practice is inspired by principles of positive psychology, guided by an empathic patient-centered approach, and based on personalized care.',
        modal: 'sleimanModal'
      },
      {
        name: 'Tarek Souaid, MD',
        role: 'Physician Researcher',
        imageUrl: tarekSouaid,
        altText: 'Tarek Souaid, MD.',
        bio:
          "I am a physician with a medical degree from Université Saint-Joseph de Beyrouth. In addition to medicine, I have expertise in research and health technology. Currently, I'm a student at Harvard T.H. Chan School of Public Health, pursuing my Masters in Public Health. I believe that digital health programs, like Goodpath's, can greatly improve patient outcomes.",
        modal: 'souaidModal'
      },
      {
        name: 'Francisco J. Barrera',
        role: 'Physician Researcher',
        imageUrl: franciscoJBarrera,
        altText: 'Francisco J. Barrera.',
        bio:
          'Dr. Barrera is a medical doctor and epidemiologist. He obtained his MD degree from the Universidad Autonoma de Nuevo Leon, and his SM in Epidemiology from the Harvard T. H. Chan School of Public Health. He is an expert in statistical analysis, epidemiological methods, and knowledge synthesis. He is currently a post-doctoral researcher in the Department of Epidemiology at the Harvard T.H. Chan School of Public Health and in the Department of Psychiatry at the Massachusetts General Hospital where he conducts clinical and population-based research. He is interested in psychiatry as a medical specialty and believes that innovative evidence-based health systems are needed to better suit the values and preferences of the patients and maximize their clinical outcomes and quality of life.',
        modal: 'barreraModal'
      }
      // More people...
    ]
  }
]

// Experts
const experts = [
  {
    title: 'Physical Therapy, Exercise and Fitness',
    anchorLink: 'therapyFitness',
    people: [
      {
        name: 'TJ Snediker, DPT, TPI',
        role: 'Physical Therapist',
        imageUrl: tjSnediker,
        altText: 'TJ Snediker, DPT, TPI.',
        bio:
          'I am the Associate Director of Musculoskeletal Health at Goodpath. My clinical background includes work as a doctor of physical therapy and as a certified personal trainer. I continue to practice physical therapy in Boston, MA. My area of clinical expertise focuses on treating back pain, musculoskeletal pain, and sports injuries. I have completed certifications for the Selective Functional Movement Assessment and the Titleist Performance Institute. I have an extensive background in ergonomics with a focus on the office and home environment. I completed undergraduate studies at the University of Notre Dame and received my Doctor of Physical Therapy degree from Boston University.',
        modal: 'snedikerModal'
      },
      {
        name: 'Abigail Freyer, PT, DPT, MFD',
        role: 'Physical Therapist',
        imageUrl: abigailFreyer,
        altText: 'Abigail Freyer, PT, DPT, MFD.',
        bio:
          "Dr. Freyer is a doctor of physical therapy with focus on orthopedic rehabilitation, specializing in manual therapy techniques and functional strengthening. She obtained her DPT degree from Northeastern University. She has worked on both sides of the coast in various multidisciplinary settings, providing intergrative care with acupuncturist, chiropractors, and medical doctors focusing on regenerative medicine. Her treatment philosophy integrates lifestyle education with strengthening programs specific to the individual's needs. She believes healing musculoskeletal dysfunction, especially chronic pain, involves a holistic approach to tackle each patients unique story.",
        modal: 'freyerModal'
      },
      {
        name: 'Michael Soares',
        role: 'Fitness Trainer',
        imageUrl: michaelSoares,
        altText: 'Michael Soares.',
        bio:
          "I'm a personal trainer and health coach. Following my bachelor degree in exercise physiology, I completed multiple trainings and eventually became Tier 3+ trainer at Equinox and founder of the New Human Project. I've trained people from diverse backgrounds, from Olympians and professional athletes to people managing chronic back pain.",
        modal: 'soaresModal'
      },
      {
        name: 'Julieta Alfonso, MS',
        role: 'Exercise Physiologist',
        imageUrl: julietaAlfonso,
        altText: 'Julieta Alfonso, MS',
        bio:
          'Ms. Alfonso is a is a Certified Health Coach from the Dr. Sears Wellness Institute. She worked as a Sports Medicine Pediatrician for 12 years in her home country of Argentina. She also has a Master’s Degree in Exercise Physiology from Universitat de Barcelona and is a Certified Road Runner Club of America Coach. She believes that the interdisciplinary approach to health is the way to support patients in finding their path to feeling and living better, and that individualization is the key to success.',
        modal: 'fulietaAlfonsoModal'
      },
      {
        name: 'Rachel Bordo, MS, ACSM-EP, NBC-HWC',
        role: 'Exercise Physiologist',
        imageUrl: rachelBordo,
        altText: 'Rachel Bordo, MS, ACSM-EP, NBC-HWC.',
        bio:
          "Ms. Bordo is an ACSM Certified Exercise Physiologist and National Board Certified Health and Wellness Coach. She has a background in cardiopulmonary rehabilitation and group exercise for special populations. She has a Bachelor's Degree in Exercise Science from The University of Akron and a Masters Degree in Exercise Physiology from Kent State University. She believes in allowing patients to choose their own path towards their health and wellness goals, focusing on intrinsic motivation.",
        modal: 'bordoModal'
      }
      // More people...
    ]
  },
  {
    title: 'Nutrition',
    anchorLink: 'nutrition',
    people: [
      {
        name: 'Linda Said, MS, RD, LDN',
        role: 'Nutritionist',
        imageUrl: lindaSaid,
        altText: 'Linda Said, MS, RD, LDN.',
        bio:
          'Ms. Said is a US Registered Dietitian with several years of experience working in different fields of nutrition science, from the individual level to a wider scale in hospitals and industry. She earned her BS in Nutrition and Dietetics from the American University of Beirut, and her MS in Nutrition Science and Policy from Tufts University in Boston. She has additional certifications in Obesity and Weight Management and Critical Care Nutrition. She worked at Massachusetts General Hospital and Brigham and Women\'s Hospital providing medical nutrition therapy for diverse populations. She strongly believes in the "food as medicine" philosophy, and is passionate about leveraging technology to help individuals make sustainable lifestyle changes to improve their overall health and wellness.',
        modal: 'saidModal'
      },
      {
        name: 'Tory Sheehan, MS, RDN',
        role: 'Nutritionist',
        imageUrl: torySheehan,
        altText: 'Tory Sheehan, MS, RDN.',
        bio:
          'Ms. Sheehan is a Registered Dietitian Nutritionist with experience providing nutrition care in multiple clinical and outpatient settings, including at the acute and critical care levels of hospitalization. She earned her Bachelor of Arts in Communication and English from the George Washington University and her Master of Science in Health Sciences with a concentration in Nutrition from Georgia State University. She has treated individuals with a wide variety of chronic conditions, and has experience in mental health care, including work with individuals at various points on the spectrum of disordered eating. She is passionate about integrative health and about providing personalized, client-centered care. She is dedicated to supporting individuals in making positive, sustainable changes on their path to optimal health and wellbeing.',
        modal: 'sheehanModal'
      }

      // More people...
    ]
  },
  {
    title: 'Psychology & Behavioral Health',
    anchorLink: 'pyschology',
    people: [
      {
        name: 'Natalija Trojanovic, MS, EdM, MHC-LP',
        role: 'Therapist',
        imageUrl: natalijaTrojanovic,
        altText: 'Natalija Trojanovic, MS, EdM, MHC-LP.',
        bio:
          "I am a mental health counselor in New York City. I use an eclectic approach with a focus on person-centered therapy and cognitive behavioral therapy. I strongly believe in the importance of research and have been involved in numerous projects during my undergraduate studies at the American University of Beirut and while pursuing my graduate degree at King's College, London and Columbia University, New York.",
        modal: 'trojanovicModal'
      },
      {
        name: 'Lauren Chaunt, MSW, MS, CNS',
        role: 'Therapist',
        imageUrl: laurenChaunt,
        altText: 'Lauren Chaunt, MSW, MS, CNS.',
        bio:
          'In addition to my work at Goodpath, I am a functional nutrition consultant, meal coach, and integrative medicine group facilitator at an inpatient eating disorder facility. I have a BA in Research Psychology and an MSW in Clinical Psychotherapy from Rhode Island College. I am a certified nutrition specialist (CNS) with an MS in Human Nutrition from the University of Bridgeport. I am passionate about empowering others to optimize their physical and mental health.',
        modal: 'chauntModal'
      },
      {
        name: 'Brianna Wells, MS',
        role: 'Head of Coaching',
        imageUrl: briannaWells,
        altText: 'Brianna Wells, MS.',
        bio:
          'Ms. Wells is a Clinical Exercise Physiologist, with additional certifications in coaching, strength and conditioning, and nutrition. She earned her BS in Exercise Science and a minor in Psychology from California State University, Long Beach and her MS in Exercise Science and Nutrition from Sacred Heart University. She has experience working with patients from different backgrounds and health status, including cardiac and pulmonary rehab, neurology, and musculoskeletal challenges. She believes in a collaborative and integrative approach to support individuals to take control of their health and quality of life in ways that are important and personalized to each person.',
        modal: 'wellsModal'
      },
      {
        name: 'Guylene Velez, LCSW',
        role: 'Therapist',
        imageUrl: guyleneVelez,
        altText: 'Guylene Velez, LCSW',
        bio:
          'Guylene Velez is a Licensed (Independent) Clinical Social Worker with a wide range of clinical experience. She graduated from Florida Atlantic University with a Master of Social Work degree in 2006. She has provided care in community mental health centers, medical settings and via telehealth. Her areas of focus include emotional disorders, grief and loss, stress, burn-out, and life transitions. She is a Board Certified-TeleMental Health Provider (BC-TMH) and a Certified Clinical Trauma Professional (CCTP). Her approach is caring, collaborative, and evidence-based.',
        modal: 'velezModal'
      },
      {
        name: 'Courtney Reynolds, BS, MPH',
        role: 'Behavioral Health Specialist',
        imageUrl: courtneyReynolds,
        altText: 'Courtney Reynolds, BS, MPH.',
        bio:
          'Ms. Reynolds is a National Board Certified Health and Wellness Coach (NBC-HWC) as well as being a Registered Yoga Teacher (RYT) through the Yoga Alliance. She earned her BS in Health and Exercise Sciences from the University of Oklahoma, a Master of Public Health (MPH) from the University of North Texas Health Science Center, and her health coach training through the Functional Medicine Coaching Academy. She has supported a wide range of individuals dealing with chronic health conditions by focusing on taking an integrative approach to health and well-being. She believes that each individual is unique and that optimal health is achievable through a whole person, collaborative approach to coaching.',
        modal: 'reynoldsModal'
      },
      {
        name: 'Mileydis Zambrana, CHC, PTA',
        role: 'Behavioral Health Specialist',
        imageUrl: mileydisZambrana,
        altText: 'Mileydis Zambrana, CHC, PTA.',
        bio:
          'Ms. Zambrana is a Board Certified Physical Therapist Assisstant with clinical experience in neuro rehab, pediatrics, geriatrics and orthopedics in the state of Florida. She holds PTA degree from Keiser University, Science Bachelors from University of Miami and she is a Certified Health Coach from the National Society of Health Coaches. She is interested in the whole person approach finding most beneficial when collaboration is key for a successful lifestyle change.',
        modal: 'zambranaModal'
      }
      // More people...
    ]
  },
  {
    title: 'Pharmacy',
    anchorLink: 'pharmacy',
    people: [
      {
        name: 'Joanna Gholmie, BS Pharm, MS',
        role: 'Pharmacist',
        imageUrl: joannaGholmie,
        altText: 'Joanna Gholmie, BS Pharm, MS.',
        bio:
          'I am a pharmacist and outcomes research professional. I have practiced in retail and clinical pharmacy in Lebanon and Switzerland prior to attending graduate school and conducting health outcomes research at the Massachusetts College of Pharmacy and Health Sciences (MCPHS) University in Boston.',
        modal: 'gholmieModal'
      },
      {
        name: 'Adriana Konstantakopoulos, BS Pharm',
        role: 'Pharmacist',
        imageUrl: adrianaKonstantakopoulos,
        altText: 'Adriana Konstantakopoulos, BS Pharm.',
        bio:
          'Ms. Konstantakopoulos is a pharmacist with expertise in integrative health. She obtained her pharmacy degree from the University of Patras, Greece with additional training in pharmacy compounding, homeopathic medicine, and cosmetology. She has several years of experience working in high volume pharmacies in Athens. She is also a Health & Well-Being Coach, trained at Duke Health. She is a firm believer in an integrative approach to health that combines conventional and complementary medicine.',
        modal: 'konstantakopoulosModal'
      }

      // More people...
    ]
  },
  {
    title: 'Mind-Body, Yoga, Taichi, Qigong',
    anchorLink: 'yogaTaichi',
    people: [
      {
        name: 'Niki Saccareccia, E-RYT',
        role: 'Meditation Instructor',
        imageUrl: nikiSaccareccia,
        altText: 'Niki Saccareccia, E-RYT.',
        bio: `Ms. Saccareccia is a yoga educator specializing in trauma-informed, individualized practice. She is an expert in integral anatomy, pain and stress management and specializes in using the physical body as an instrument for cultivating mental and emotional wellbeing through yogic science. She earned her BA degree in Psychology from University of South Florida and her eRYT designation from the internationally recognized Yoga Alliance. She has over a decade of experience teaching people how to access their mind body connection through breathwork, meditation and yoga using traditional methods in modern ways.
          She spent her early career as a clinical interventionist in the field of Applied Behavioral Analysis. Her training includes ongoing study with industry leaders like Leslie Kaminoff, Jill Miller and Gil Hedley along with training in social emotional learning facilitation, mindfulness-based meditation technique, ongoing study at The Breathing Project, Institute for Anatomical Research, and Embodied Philosophy School. She leads workshops, trainings and retreats worldwide.
          Her approach relies on a pragmatic and systematic approach to teach others how to not only realize but sustain healthy habits of wellbeing for a more enjoyable quality of life.`,
        modal: 'sarrarecciaModal'
      },
      {
        name: 'Jennifer Graham, RYT',
        role: 'Yoga Specialist',
        imageUrl: jenniferGraham,
        altText: 'Jennifer Graham, RYT.',
        bio:
          'Ms. Graham a Master Trainer and Instructor for a Pilates-style national fitness franchise. Following her degree from Cal State Northridge, she turned her focus into fitness and spent the past 13 years in the industry continuing her fitness education. She is a Registered Yoga Teacher (RYT) and Reiki II practitioner from Hot Asana University. She has trained clients from all fitness backgrounds and provides personalized training with the goal of improving strength, flexibility and overall wellness.',
        modal: 'grahamModal'
      },
      {
        name: 'Razmig Azezian',
        role: 'QiGong Master',
        imageUrl: razmigAzezian,
        altText: 'Razmig Azezian.',
        bio:
          'Razmig is a Pranic Healing and Arhatic Yoga practitioner with more than 18 years of clinical experience. He studied biology in Lebanon and physiotherapy and Chinese medicine at the Beijing University of Physical Education.',
        modal: 'azezianModal'
      }
      // More people...
    ]
  }
]

const physicanAnchors = [
  {
    name: 'Leadership',
    link: '#leadership'
  },
  {
    name: 'Musculoskeletal Conditions',
    link: '#mskLeadership'
  },
  {
    name: 'Sleep Disorders',
    link: '#sleepLeadership'
  },
  {
    name: 'Digestive Health',
    link: '#digestiveLeadership'
  },
  {
    name: 'Long COVID',
    link: '#covidLeadership'
  },
  {
    name: 'Mental Health',
    link: '#mentalLeadership'
  },
  {
    name: 'Mind-Body Medicine',
    link: '#mindMedicine'
  },
  {
    name: 'Research & Clinical Development',
    link: '#researchDevelopment'
  }
  // More people...
]

const expertAnchors = [
  {
    name: 'Physical Therapy, Exercise and Fitness',
    link: '#therapyFitness'
  },
  {
    name: 'Nutrition',
    link: '#nutrition'
  },
  {
    name: 'Psychology & Behavioral Health',
    link: '#pyschology'
  },
  {
    name: 'Pharmacy',
    link: '#pharmacy'
  },
  {
    name: 'Mind-Body, Yoga, Taichi, Qigong',
    link: '#yogaTaichi'
  }
  // More people...
]

export default function TeamPageNew() {
  const [modalIsOpen, setIsOpen] = React.useState(
    physicians.reduce(
      (obj, physician) => ({
        ...obj,
        ...physician.people.reduce(
          (physicianObj, person) => ({
            ...physicianObj,
            [person.modal]: false
          }),
          {}
        )
      }),
      {}
    )
  )

  return (
    <>
      {/* Hero */}
      <div className="relative">
        <div className="relative z-20 px-4 pt-6 pb-4 mx-auto max-w-7xl sm:px-6 lg:px-8 md:pt-12">
          <h1 className="text-3xl font-thin leading-tight md:text-6xl text-gp-violet">
            Our Medical Team
          </h1>

          <p className="mt-4 text-base leading-tight md:text-2xl text-gp-grey-100">
            Goodpath care is driven by a multidisciplinary medical and
            scientific team that spans more than 20 disciplines. Our team of
            physicians, scientists, and healthcare professionals brings together
            the world's best experts in conventional and complementary care.
          </p>

          <p className="mt-6 text-base leading-tight md:text-2xl text-gp-grey-100">
            Goodpath delivers evidence-based integrative health in a value-based
            care model. This whole-person hyper-personalized approach to care is
            the most effective way to treat chronic conditions, including{' '}
            <Link
              className="font-normal underline text-gp-grey-100 hover:text-gp-violet focus:text-gp-violet"
              to="/conditions/back-pain"
            >
              musculoskeletal pain
            </Link>
            ,{' '}
            <Link
              className="font-normal underline text-gp-grey-100 hover:text-gp-violet focus:text-gp-violet"
              to="/conditions/sleep"
            >
              sleep
            </Link>
            ,{' '}
            <Link
              className="font-normal underline text-gp-grey-100 hover:text-gp-violet focus:text-gp-violet"
              to="/conditions/ibs"
            >
              digestive health
            </Link>
            ,{' '}
            <Link
              className="font-normal underline text-gp-grey-100 hover:text-gp-violet focus:text-gp-violet"
              to="/conditions/long-covid"
            >
              long COVID
            </Link>
            , and{' '}
            <Link
              className="font-normal underline text-gp-grey-100 hover:text-gp-violet focus:text-gp-violet"
              to="/conditions/mental-health"
            >
              mental health
            </Link>
            .
          </p>
        </div>
        <svg
          className="absolute top-0 left-0 z-0 max-w-400"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 518 500"
        >
          <path
            d="M288 0A270 270 0 0 1 0 269v231h18c276 0 500-224 500-500H288Z"
            fill="#C1E0E8"
          />
        </svg>
      </div>

      <div className="flex flex-col items-center justify-center my-6">
        <Ring className="w-12 h-12 fill-current text-gp-honey" />
        <Line />
        <Circle className="w-12 h-12 fill-current text-gp-honey" />
      </div>

      <div className="max-w-5xl mx-auto">
        <div className="flex items-center justify-center mb-8">
          <span className="text-2xl leading-none text-center text-gp-violet">
            Jump to:
          </span>
        </div>
        <h2 className="text-2xl font-normal leading-snug text-center uppercase text-gp-violet">
          Multi-specialty group of highly trained physicians &amp; PHDs
        </h2>
        <ul className="flex flex-wrap items-center justify-center px-4 mt-4 space-x-4 list-none">
          {physicanAnchors.map((anchor) => (
            <li key={anchor.name}>
              <a
                href={anchor.link}
                className="block px-4 pt-3 pb-4 mb-1 text-xl font-normal leading-none text-center text-white transition-all duration-300 ease-in-out bg-gp-violet rounded-20 hover:no-underline hover:bg-gp-violet-120 focus:bg-gp-violet-120"
              >
                {anchor.name}
              </a>
            </li>
          ))}
        </ul>

        <h2 className="mt-8 text-2xl font-normal leading-snug text-center uppercase text-gp-violet">
          Discipline experts in conventional and complementary care
        </h2>
        <ul className="flex flex-wrap items-center justify-center px-4 mt-4 space-x-4 list-none">
          {expertAnchors.map((anchor) => (
            <li key={anchor.name}>
              <a
                href={anchor.link}
                className="block px-4 pt-3 pb-4 mb-1 text-xl font-normal leading-none text-center transition-all duration-300 ease-in-out text-gp-grey-100 bg-gp-grapefruit rounded-20 hover:no-underline hover:bg-gp-grapefruit-70 focus:bg-gp-grapefruit-70"
              >
                {anchor.name}
              </a>
            </li>
          ))}
        </ul>
      </div>

      {/* Start physician map */}
      <div className="px-4 pb-12 mx-4 mt-6 space-y-8 border-b-2 border-l-2 xl:mx-auto rounded-bl-xxlg md:space-y-12 lg:space-y-20 border-gp-violet max-w-7xl sm:px-6 lg:px-8 lg:pb-24 md:mt-12">
        <div className="z-10 flex items-start mb-4 -ml-10 md:mb-6 md:-ml-12 lg:-ml-14">
          <RingFilled className="flex-shrink-0 w-12 h-12 mr-8 -mt-1 fill-current text-gp-violet" />
          <h2 className="text-2xl font-normal leading-snug uppercase text-gp-grey-100 md:text-4xl">
            Multi-specialty group of highly trained physicians &amp; PHDs
          </h2>
        </div>

        {physicians.map((physician) => (
          <div key={physician.title}>
            <div className="z-10 flex items-center mb-4 -ml-10 md:mb-6 md:-ml-12 lg:-ml-14">
              <Circle className="flex-shrink-0 w-12 h-12 mr-8 fill-current text-gp-violet" />
              <h2
                id={physician.anchorLink}
                className="text-3xl leading-none text-gp-violet scroll-margin"
              >
                {physician.title}
              </h2>
            </div>

            <ul className="grid list-none m:grid-cols-2 sm:gap-x-6 lg:grid-cols-3 lg:gap-x-8 gap-y-6">
              {physician.people.map((person) => (
                <li key={person.name}>
                  <div className="">
                    <div className="flex items-center justify-center w-full px-10">
                      <img
                        className="max-h-200"
                        src={person.imageUrl}
                        alt={person.altText}
                      />
                    </div>

                    <div className="flex flex-col items-center justify-center px-6 py-4 space-y-1 text-lg leading-tight bg-gp-violet rounded-20">
                      <h3 className="text-lg leading-snug text-center text-white">
                        {person.name}
                      </h3>
                      <p className="text-lg text-gp-honey">{person.role}</p>
                      <button
                        className="text-base text-white underline"
                        onClick={() =>
                          setIsOpen((modalIsOpen) => ({
                            ...modalIsOpen,
                            [person.modal]: true
                          }))
                        }
                      >
                        read more
                      </button>
                    </div>
                  </div>
                  <Modal
                    isOpen={modalIsOpen[person.modal]}
                    onRequestClose={() =>
                      setIsOpen((modalIsOpen) => ({
                        ...modalIsOpen,
                        [person.modal]: false
                      }))
                    }
                    className="fixed top-0 left-0 right-0 m-4 md:top-2/12"
                  >
                    <div className="relative w-full max-w-4xl px-4 py-8 mx-auto md:px-10 md:py-14 bg-gp-violet rounded-20">
                      <button
                        onClick={() =>
                          setIsOpen((modalIsOpen) => ({
                            ...modalIsOpen,
                            [person.modal]: false
                          }))
                        }
                        className="absolute top-0 right-0 flex items-center justify-center w-16 h-16 p-4 text-white transition-all duration-300 ease-in-out hover:bg-gp-violet-120 focus:bg-gp-violet-120 rounded-tr-20"
                      >
                        <svg
                          className="w-6 h-6"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                      <div className="flex flex-col overflow-auto md:overflow-hidden md:flex-row max-h-400 md:max-h-full">
                        <div className="flex-1 pr-4">
                          <h3 className="text-lg text-white">{person.name}</h3>
                          <p className="mt-2 text-lg text-gp-honey">
                            {person.role}
                          </p>
                          <p className="mt-4 text-base leading-normal text-gp-white">
                            {person.bio}
                          </p>
                        </div>
                        <div className="flex items-end justify-center mt-4 md:mt-0">
                          <img
                            className="pt-4 bg-white max-w-200"
                            src={person.imageUrl}
                            alt={person.altText}
                          />
                        </div>
                      </div>
                    </div>
                  </Modal>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      {/* Start experts map */}
      <div className="px-4 pb-12 mx-4 mt-10 mb-10 space-y-8 border-b-2 border-l-2 xl:mx-auto rounded-bl-xxlg md:space-y-12 lg:space-y-20 border-gp-violet max-w-7xl sm:px-6 lg:px-8 lg:pb-24 md:mt-20 md:mb-20">
        <div className="z-10 flex items-start mb-4 -ml-10 md:mb-6 md:-ml-12 lg:-ml-14">
          <RingFilled className="flex-shrink-0 w-12 h-12 mr-8 -mt-1 fill-current text-gp-grapefruit" />
          <h2 className="text-2xl font-normal leading-snug uppercase text-gp-grey-100 md:text-4xl">
            Discipline experts in conventional and complementary care
          </h2>
        </div>

        {experts.map((expert) => (
          <div key={expert.title}>
            <div className="z-10 flex items-center mb-4 -ml-10 md:mb-6 md:-ml-12 lg:-ml-14">
              <Circle className="flex-shrink-0 w-12 h-12 mr-8 fill-current text-gp-grapefruit" />
              <h2
                id={expert.anchorLink}
                className="text-3xl leading-none text-gp-violet scroll-margin"
              >
                {expert.title}
              </h2>
            </div>
            <ul className="grid list-none m:grid-cols-2 sm:gap-x-6 lg:grid-cols-3 lg:gap-x-8 gap-y-6">
              {expert.people.map((person) => (
                <li key={person.name}>
                  <div className="">
                    <div className="flex items-center justify-center w-full px-10">
                      <img
                        className="max-h-200"
                        src={person.imageUrl}
                        alt={person.altText}
                      />
                    </div>

                    <div className="flex flex-col items-center justify-center px-6 py-4 space-y-1 text-lg leading-tight bg-gp-violet rounded-20">
                      <h3 className="text-lg leading-snug text-center text-white">
                        {person.name}
                      </h3>
                      <p className="text-lg text-gp-honey">{person.role}</p>
                      <button
                        className="text-base text-white underline"
                        onClick={() =>
                          setIsOpen((modalIsOpen) => ({
                            ...modalIsOpen,
                            [person.modal]: true
                          }))
                        }
                      >
                        read more
                      </button>
                    </div>
                  </div>
                  <Modal
                    isOpen={modalIsOpen[person.modal]}
                    onRequestClose={() =>
                      setIsOpen((modalIsOpen) => ({
                        ...modalIsOpen,
                        [person.modal]: false
                      }))
                    }
                    className="fixed top-0 left-0 right-0 m-4 md:top-2/12"
                  >
                    <div className="relative w-full max-w-4xl px-4 py-8 mx-auto md:px-10 md:py-14 bg-gp-violet rounded-20">
                      <button
                        onClick={() =>
                          setIsOpen((modalIsOpen) => ({
                            ...modalIsOpen,
                            [person.modal]: false
                          }))
                        }
                        className="absolute top-0 right-0 flex items-center justify-center w-16 h-16 p-4 text-white transition-all duration-300 ease-in-out hover:bg-gp-violet-120 focus:bg-gp-violet-120 rounded-tr-20"
                      >
                        <svg
                          className="w-6 h-6"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                      <div className="flex flex-col overflow-auto md:overflow-hidden md:flex-row max-h-400 md:max-h-full">
                        <div className="flex-1 pr-4">
                          <h3 className="text-lg text-white">{person.name}</h3>
                          <p className="mt-2 text-lg text-gp-honey">
                            {person.role}
                          </p>
                          <p className="mt-4 text-base leading-normal text-gp-white">
                            {person.bio}
                          </p>
                        </div>
                        <div className="flex items-end justify-center mt-4 md:mt-0">
                          <img
                            className="pt-4 bg-white max-w-200"
                            src={person.imageUrl}
                            alt={person.altText}
                          />
                        </div>
                      </div>
                    </div>
                  </Modal>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </>
  )
}
